html,
body {
  padding: 0;
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */
  font-family: "Lato", sans-serif !important;
  overflow: auto;
}

body {
  background-color: #f7f7f7 !important;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.image img {
  width: 100% !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.react-swipeable-view-container {
  align-items: center;
}
